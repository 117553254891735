#block-05 {
	position: relative;
	background-color: #ffffff;
	padding-top: 70px;
	padding-bottom: 80px;
	overflow: hidden;
	@media screen and (max-width: 767px) {
		padding-top: 40px;
		padding-bottom: 40px;
	}

	&:before,
	&:after {
		content: '';
		position: absolute;
		right: -10px;
		top: 0;
		width: 60px;
		height: 200%;
		background-color: #fafafc;
		transform: rotate(20deg);
		transform-origin: top right;
		z-index: 0;
	}

	&:after { 
		right: calc(-10px + 120px);
	}
	.content-wrap {
		position: relative;
		z-index: 10;
	}

	.block-title {
		display: flex;
		justify-content: center;
		h2 {
			margin: 0;
			padding: 20px 80px;
			font-size: 30px;
			background-color: #f3f3f7;
			color: #4e4468;
			border-radius: 100px;
			text-transform: uppercase;
			@media screen and (max-width: 1279px) {
				font-size: 24px;
				line-height: 32px;
			}
			@media screen and (max-width: 767px) {
				text-align: center;
				padding: 10px 30px;
				font-size: 18px;
				line-height: 24px;
			}
		}
	}

	#review-slider {
		margin-top: 60px;
		@media screen and (max-width: 767px) {
			margin-top: 50px;
		}
	}
	
}