.page-footer {
	padding-top: 25px;
	padding-bottom: 25px;
	background: linear-gradient(#147dbd, #012aaf);
	@media screen and (max-width: 1279px) {
		padding-top: 20px;
		padding-bottom: 20px;
	}
	@media screen and (max-width: 767px) {
		padding-top: 10px;
		padding-bottom: 10px;
	}

	.content-wrap {
		display: flex;
		align-items: center;
		@media screen and (max-width: 767px) {
			display: block;
		}
	}

	.footer_side--right {
		margin-left: auto;
		display: flex;
		align-items: center;
		@media screen and (max-width: 767px) {
			margin-top: 30px;
		}
	}

	.logo {
		display: flex;
		align-items: center;
		text-decoration: none;
		color: white;
		img {
			height: 73px;
			width: auto;
			@media screen and (max-width: 1279px) {
				height: 50px;
			}
			@media screen and (max-width: 767px) {
				height: 40px;
			}
		}
		span {
			padding-left: 10px;
			font-size: 28px;
			line-height: 42px;
			font-style: italic;
			@media screen and (max-width: 1279px) {
				font-size: 24px;
			}
			@media screen and (max-width: 767px) {
				font-size: 18px;
			}
		}
	}

	.contacts {
		span {
			font-size: 14px;
			color: white;
			@media screen and (max-width: 767px) {
				font-size: 12px;
			}
		}
	}

	.email a {
		text-decoration: none;
		color: white;
		font-size: 26px;
		@media screen and (max-width: 767px) {
			font-size: 18px;
		}
	}

	
}