#block-06 {
	position: relative;
	background: url("../img/office3-bg.png") #f6f6f8;
	background-repeat: no-repeat;
	background-position: right calc(100% - 80px);
	padding-top: 70px;
	padding-bottom: 80px;
	overflow: hidden;
	@media screen and (max-width: 767px) {
		padding-top: 40px;
		padding-bottom: 40px;
		background: #f6f6f8;
	}

	&:before,
	&:after {
		content: '';
		position: absolute;
		left: 12%;
		bottom: 0;
		width: 60px;
		height: 200%;
		background-color: #fafafc;
		transform: rotate(20deg);
		transform-origin: bottom left;
		z-index: 0;
	}

	&:after { 
		left: calc(12% + 120px);
	}
	.content-wrap {
		position: relative;
		z-index: 10;
	}

	.block-title {
		display: flex;
		justify-content: center;
		h2 {
			margin: 0;
			padding: 20px 80px;
			font-size: 24px;
			font-style: italic;
			background-color: #433a5c;
			color: #fff;
			border-radius: 100px;
			text-transform: uppercase;
			@media screen and (max-width: 1279px) {
				font-size: 20px;
				line-height: 28px;
			}
			@media screen and (max-width: 767px) {
				text-align: center;
				padding: 10px 30px;
				font-size: 16px;
				line-height: 24px;
			}
		}
	}

	.steps {
		width: 870px;
		margin: 0 auto;
		@media screen and (max-width: 1023px) {
			width: 100%;
		}
	}
}