.block-about-list {
	width: 100%;
	.about-list__title {
		background-color: #4e4468;
		padding-left: 50px;
		@media screen and (max-width: 1279px) {
			padding-left: 30px;
		}
		@media screen and (max-width: 767px) {
			padding: 10px 20px;
		}
		p {
			margin: 0;
			font-size: 18px;
			line-height: 50px;
			color: white;
			@media screen and (max-width: 1279px) {
				font-size: 16px;
				line-height: 28px;
			}
			@media screen and (max-width: 767px) {
				font-size: 14px;
				line-height: 20px;
			}
		}
	}

	.about-list__content {
		ul {
			list-style: none;
			margin: 0;
			padding: 0;
			li {
				background-color: #fff;
				color: #4e4468;
				font-size: 18px;
				line-height: 30px;
				margin-top: 7px;
				padding-left: 20px;
				@media screen and (max-width: 1279px) {
					font-size: 16px;
					line-height: 28px;
				}
				@media screen and (max-width: 767px) {
					padding: 5px 20px;
					font-size: 14px;
					line-height: 20px;
				}

				&:before {
					content: '-';
					padding-right: 5px;
				}
			}
		}
	}
}