.page-header {
	padding-top: 25px;
	padding-bottom: 25px;
	background: linear-gradient(#012aaf, #147dbd);
	@media screen and (max-width: 1279px) {
		padding-top: 20px;
		padding-bottom: 20px;
	}
	@media screen and (max-width: 767px) {
		padding-top: 10px;
		padding-bottom: 10px;
	}

	.content-wrap {
		display: flex;
		align-items: center;
	}

	.header_side--right {
		margin-left: auto;
		display: flex;
		align-items: center;
	}

	.logo {
		display: flex;
		align-items: center;
		text-decoration: none;
		color: white;
		img {
			height: 73px;
			width: auto;
			@media screen and (max-width: 1279px) {
				height: 50px;
			}
			@media screen and (max-width: 767px) {
				height: 40px;
			}
		}
		span {
			padding-left: 10px;
			font-size: 28px;
			line-height: 42px;
			font-style: italic;
			@media screen and (max-width: 1279px) {
				font-size: 24px;
			}
			@media screen and (max-width: 767px) {
				font-size: 18px;
			}
		}
	}

	.contacts {
		@media screen and (max-width: 1023px) {
			display: none
		}
	}

	.phone a {
		text-decoration: none;
		color: white;
		font-size: 26px;
		@media screen and (max-width: 1279px) {
			font-size: 24px;
		}
	}

	.work-time span {
		font-size: 22px;
		color: #8bc4f6;
		@media screen and (max-width: 1279px) {
			font-size: 20px;
		}
	}

	.whatsapp {
		margin-left: 20px;
		a {
			display: flex;
			align-items: center;
			text-decoration: none;
			background-color: #fff;
			padding: 5px 20px;
			border-radius: 30px;
			@media screen and (max-width: 767px) {
				padding: 8px;
			}
			.icon {
				width: 40px;
				height: 40px;
				fill: #625873;
				@media screen and (max-width: 767px) {
					width: 30px;
					height: 30px;
				}
			}
			span {
				padding-left: 15px;
				font-size: 16px;
				font-weight: bold;
				line-height: 22px;
				color: #625873;
				@media screen and (max-width: 767px) {
					display: none;
				}
			}
		}
	}

	.teamviewer {
		margin-left: 20px;
		width: 54px;
		height: 54px;
		background: url('../img/teamviewer.png');
		background-repeat: no-repeat;
		@media screen and (max-width: 1023px) {
			display: none;
		}
	}
}