#block-04 {
	position: relative;
	background-color: #f6f6f8;
	padding-top: 70px;
	padding-bottom: 60px;
	overflow: hidden;
	@media screen and (max-width: 767px) {
		padding-top: 40px;
		padding-bottom: 40px;
	}

	&:before,
	&:after {
		content: '';
		position: absolute;
		left: -2%;
		bottom: 0;
		width: 60px;
		height: 200%;
		background-color: #fcfcfc;
		transform: rotate(20deg);
		transform-origin: bottom left;
		z-index: 0;
	}

	&:after { 
		left: calc(-2% + 120px);
	}

	.content-wrap {
		position: relative;
		z-index: 10;
	}

	.block-title {
		display: flex;
		justify-content: center;
		h2 {
			margin: 0;
			padding: 20px 80px;
			font-size: 30px;
			background-color: #f6f822;
			color: #4e4468;
			border-radius: 100px;
			text-transform: uppercase;
			@media screen and (max-width: 1279px) {
				font-size: 24px;
				line-height: 32px;
			}
			@media screen and (max-width: 767px) {
				text-align: center;
				padding: 10px 30px;
				font-size: 16px;
				line-height: 24px;
			}
			@media screen and (max-width: 374px) {
				font-size: 14px;
				line-height: 20px;
			}
		}
	}

	.content-part {
		margin-top: 35px;
		@media screen and (max-width: 767px) {
			margin-top: 0;
		}
		.content__side--left {
			@media screen and (max-width: 1023px) {
				width: 60%;
				padding-right: 40px;
				box-sizing: border-box;
			}
			@media screen and (max-width: 767px) {
				width: 100%;
				padding-right: 0;
			}
		}
		.content__side--right {
			text-align: center;
			@media screen and (max-width: 1023px) {
				width: 40%;
			}
			@media screen and (max-width: 767px) {
				display: none;
			}

			img {
				@media screen and (max-width: 1023px) {
					width: 100%;
					height: auto;
				}
			}
		}
	}

	.block-about-list {
		margin-top: 30px;
	}

	.offer-block {
		padding-left: 20px;
		@media screen and (max-width: 1023px) {
			margin-top: 50px;
		}
		@media screen and (max-width: 767px) {
			margin-top: 30px;
		}
		span {
			position: relative;
			display: block;
			font-size: 18px;
			line-height: 24px;
			color: #4e4468;
			@media screen and (max-width: 1919px) {
				font-size: 16px;
				line-height: 28px;
			}
			@media screen and (max-width: 1279px) {
				font-size: 14px;
				line-height: 24px;
			}
			@media screen and (max-width: 767px) {
				padding-top: 10px;
				padding-right: 25px;
				padding-bottom: 10px;
			}
			b {
				padding: 10px 25px;
				text-transform: uppercase;
				background-color: #73c668;
				color: white;
				border-radius: 50px;
				@media screen and (max-width: 767px) {
					padding: 0;
					background-color: transparent;
					color: #4e4468;
				}
			}
		}
	}
	
}