.content-wrap {
	max-width: 1200px;
	margin: 0 auto;
	box-sizing: border-box;
	@media screen and (max-width: 1279px) {
		width: 100%;
		max-width: none;
		padding-left: 40px;
		padding-right: 40px;
	}
	@media screen and (max-width: 767px) {
		padding-left: 20px;
		padding-right: 20px;
	}
}

.content-part {
	display: flex;
	.content__side {
		width: 50%;
	}
}