#block-03 {
	position: relative;
	background-color: #433a5c;
	padding-top: 70px;
	padding-bottom: 35px;
	overflow: hidden;
	
	.decore {
		position: absolute;
		left: 0;
		top: 0;
		width: 265px;
		height: 200%;
		background-color: #483f63;
		transform: rotate(20deg);
		transform-origin: top right;
		z-index: 0;
	}

	&:before,
	&:after {
		content: '';
		position: absolute;
		right: -10px;
		top: 0;
		width: 60px;
		height: 200%;
		background-color: #483f63;
		transform: rotate(20deg);
		transform-origin: top right;
		z-index: 0;
	}

	&:after { 
		right: calc(-10px + 120px);
	}
	.content-wrap {
		position: relative;
		z-index: 10;
	}

	.block-title {
		display: flex;
		justify-content: center;
		h2 {
			margin: 0;
			padding: 20px 80px;
			font-size: 30px;
			background-color: #fff;
			color: #4e4468;
			border-radius: 100px;
			text-transform: uppercase;
			@media screen and (max-width: 1279px) {
				font-size: 24px;
				line-height: 32px;
			}
			@media screen and (max-width: 767px) {
				text-align: center;
				padding: 10px 30px;
				font-size: 18px;
				line-height: 24px;
			}
		}
	}

	.block-advantages {
		margin-top: 50px;
	}

	.advantages {
		display: flex;
		flex-wrap: wrap;

		.advantage {
			width: 25%;
			@media screen and (max-width: 1023px) {
				width: 50%;
			}
			@media screen and (max-width: 374px) {
				width: 100%;
			}
		}
	}
	.advantage {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-bottom: 40px;
		.advantage__media {
			width: 130px;
			height: 100px;
			@media screen and (max-width: 1279px) {
				width: 80px;
				height: 61px;
			}
		}
		.advantages__desc {
			margin-top: 18px;
			padding-top: 20px;
			padding-left: 50px;
			padding-right: 50px;
			border-top: 7px solid;
			text-align: center;
			@media screen and (max-width: 1279px) {
				padding-left: 25px;
				padding-right: 25px;
				width: 100%;
				box-sizing: border-box;
			}
			@media screen and (max-width: 767px) {
				padding-left: 15px;
				padding-right: 15px;
			}
			p {
				margin: 0;
				font-size: 20px;
				line-height: 24px;
				color: white;
				@media screen and (max-width: 1279px) {
					font-size: 18px;
					line-height: 22px;
				}
				@media screen and (max-width: 767px) {
					font-size: 16px;
					line-height: 20px;
				}
			}
		}
	}

	.advantage__1 {
		.advantage__media {
			background: url('../img/advantage-1.png');
			background-repeat: no-repeat;
			background-position: center;
			background-size: contain;
		}
		.advantages__desc {
			border-color: #509b47;
		}
	}
	.advantage__2 {
		.advantage__media {
			background: url('../img/advantage-2.png');
			background-repeat: no-repeat;
			background-position: center;
			background-size: contain;
		}
		.advantages__desc {
			border-color: #32afb7;
		}
	}
	.advantage__3 {
		.advantage__media {
			background: url('../img/advantage-3.png');
			background-repeat: no-repeat;
			background-position: center;
			background-size: contain;
		}
		.advantages__desc {
			border-color: #2472c2;
		}
	}
	.advantage__4 {
		.advantage__media {
			background: url('../img/advantage-4.png');
			background-repeat: no-repeat;
			background-position: center;
			background-size: contain;
		}
		.advantages__desc {
			border-color: #444fe6;
		}
	} 
	.advantage__5 {
		.advantage__media {
			background: url('../img/advantage-5.png');
			background-repeat: no-repeat;
			background-position: center;
			background-size: contain;
		}
		.advantages__desc {
			border-color: #af99d5;
		}
	}
	.advantage__6 {
		.advantage__media {
			background: url('../img/advantage-6.png');
			background-repeat: no-repeat;
			background-position: center;
			background-size: contain;
		}
		.advantages__desc {
			border-color: #d289d1;
		}
	}
	.advantage__7 {
		.advantage__media {
			background: url('../img/advantage-7.png');
			background-repeat: no-repeat;
			background-position: center;
			background-size: contain;
		}
		.advantages__desc {
			border-color: #d66a95;
		}
	}
	.advantage__8 {
		.advantage__media {
			background: url('../img/advantage-8.png');
			background-repeat: no-repeat;
			background-position: center;
			background-size: contain;
		}
		.advantages__desc {
			border-color: #f66868;
		}
	}
}