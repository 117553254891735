#block-01 {
	position: relative;
	background: url('../img/office-bg.png') #f6f6f8;
	background-position: right 40px;
	background-repeat: no-repeat;
	padding-bottom: 75px;
	overflow: hidden;
	@media screen and (max-width: 1919px) {
		background-size: 48%;
	}
	@media screen and (max-width: 1279px) {
		background-size: 40%;
	}
	@media screen and (max-width: 1023px) {
		background: #f6f6f8;
	}

	&:before,
	&:after {
		content: '';
		position: absolute;
		left: 12%;
		bottom: 0;
		width: 60px;
		height: 200%;
		background-color: #fcfcfc;
		transform: rotate(20deg);
		transform-origin: bottom left;
		z-index: 0;
		@media screen and (max-width: 1023px) {
			left: -10%
		}
	}

	&:after { 
		left: calc(12% + 120px);
		@media screen and (max-width: 1023px) {
			left: calc(-10% + 120px);
		}
	}
	.content-wrap {
		position: relative;
		z-index: 10;
	}
	.block-titles {
		padding-top: 190px;
		color: #4e4468;
		@media screen and (max-width: 1919px) {
			padding-top: 160px;
		}
		@media screen and (max-width: 1279px) {
			padding-top: 120px;
		}
		@media screen and (max-width: 1023px) {
			padding-top: 70px;
		}
		@media screen and (max-width: 767px) {
			padding-top: 40px;
		}

		h1 {
			margin: 0;
			font-size: 24px;
			line-height: 30px;
			text-transform: uppercase;
			@media screen and (max-width: 1919px) {
				font-size: 22px;
			}
			@media screen and (max-width: 1279px) {
				font-size: 20px;
			}
			@media screen and (max-width: 767px) {
				font-size: 16px;
				line-height: 26px;
			}
			@media screen and (max-width: 374px) {
				font-size: 14px;
				line-height: 22px;
			}
		}

		h2 {
			margin: 20px 0 0 0;
			font-size: 70px;
			line-height: 76px;
			text-transform: uppercase;
			@media screen and (max-width: 1919px) {
				font-size: 60px;
			}
			@media screen and (max-width: 1279px) {
				font-size: 50px;
				line-height: 60px;
			}
			@media screen and (max-width: 767px) {
				margin-top: 10px;
				font-size: 30px;
				line-height: 42px;
			}
			@media screen and (max-width: 374px) {
				font-size: 24px;
				line-height: 30px;
			}
		}

		h3 {
			position: relative;
			margin: 30px 0 0 0;
			display: inline-block;
			vertical-align: middle;
			padding-left: 25px;
			font-size: 18px;
			line-height: 30px;
			text-transform: uppercase;
			background-color: #eeecf1;
			@media screen and (max-width: 1919px) {
				font-size: 16px;
				line-height: 28px;
			}
			@media screen and (max-width: 1279px) {
				font-size: 14px;
				line-height: 24px;
			}
			@media screen and (max-width: 767px) {
				padding-top: 10px;
				padding-right: 25px;
				padding-bottom: 10px;
			}
			@media screen and (max-width: 374px) {
				font-size: 12px;
				line-height: 20px;
			}
			b {
				display: inline-block;
				padding: 10px 15px;
				color: white;
				background-color: #4e4468;
				@media screen and (max-width: 767px) {
					padding: 0;
					background-color: transparent;
					color:	#4e4468;
				}
			}
		}

		em {
			display: block;
			margin: 30px 0 0 0;
			font-size: 14px;
			line-height: 21px;
			text-transform: uppercase;
			@media screen and (max-width: 1279px) {
				margin-top: 20px;
			}
			@media screen and (max-width: 767px) {
				margin-top: 10px;
				font-size: 12px;
				line-height: 18px;
			}
		}
	}

	.block-images {
		display: none;
		@media screen and (max-width: 1023px) {
			display: block;
			text-align: right;
		}
		img {
			height: auto;
			@media screen and (max-width: 1023px) {
				width: 75%;
				transform: translateX(40px)
			}
			@media screen and (max-width: 767px) {
				width: 90%;
			}
		}
	}

	.block-slogan {
		margin-top: 160px;
		display: flex;
		justify-content: center;
		@media screen and (max-width: 1919px) {
			margin-top: 130px;
		}
		@media screen and (max-width: 1279px) {
			margin-top: 100px;
		}
		@media screen and (max-width: 1023px) {
			margin-top: 0;
		}

		.block-slogan-wrap {
			padding: 30px 50px;
			background-color: #4e4468;
			border-radius: 100px;
			@media screen and (max-width: 1919px) {
				padding: 25px 40px;
			}
			@media screen and (max-width: 767px) {
				padding: 10px 30px;
			}
			p {
				margin: 0;
				font-size: 24px;
				line-height: 30px;
				color: white;
				@media screen and (max-width: 1919px) {
					font-size: 22px;
				}
				@media screen and (max-width: 767px) {
					font-size: 14px;
					line-height: 20px;
				}
			}
		}
	}
	
}