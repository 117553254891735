#review-slider {

	.slider-container {
		margin-bottom: 50px;
	}
	.slider-control {
		display: flex;
		align-items: center;
		justify-content: center;
		.slider-button {
			width: 45px;
			height: 45px;
			@media screen and (max-width: 767px) {
				width: 30px;
				height: 30px;
			}
			cursor: pointer;
			.icon {
				width: 100%;
				height: 100%;
				fill: #4e4468;
			}

			&.slider-button--prev {
				margin-right: 20px;
				@media screen and (max-width: 767px) {
					margin-right: 10px;
				}
				.icon {
					transform: rotate(180deg)
				}
			}
		}

		.slider-dots {
			position: relative;
			.slick-dots {
				position: relative;
				bottom: 0;
				display: flex;
				align-items: center;
				li {
					width: auto;
					height: auto;
					margin-right: 20px;
					margin-left: 0;
					@media screen and (max-width: 767px) {
						margin-right: 10px;
					}
					button {
						padding: 0;
						width: 15px;
						height: 15px;
						background-color: #f3f3f7;
						border: 2px solid #433a5c;
						border-radius: 50%;
						box-sizing: border-box;
						transition: .3s;
						@media screen and (max-width: 767px) {
							width: 10px;
							height: 10px;
						}
						&:before {
							display: none;
						}
					}

					&.slick-active {
						button {
							background-color: #433a5c;
						}
					}
				}
			}
		}
	}


	.review {
		color: #4e4468;
		.review__person {
			span {
				font-size: 22px;
				line-height: 30px;
				font-weight: bold;
				@media screen and (max-width: 767px) {
					font-size: 20px;
					line-height: 28px;
				}
			}
		}

		.review__person-post {
			span {
				font-size: 20px;
				line-height: 30px;
				@media screen and (max-width: 767px) {
					font-size: 18px;
					line-height: 26px;
				}
			}
		}

		.review__text {
			margin-top: 10px;
			&:before {
				content: '';
				display: block;
				height: 2px;
				width: 30px;
				margin-bottom: 10px;
				background-color: #4e4468;
			}
			p {
				font-size: 20px;
				line-height: 30px;
				margin: 0;
				@media screen and (max-width: 767px) {
					font-size: 18px;
					line-height: 26px;
				}
			}
		}
	}
}