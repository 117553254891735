.steps {

	.steps__title {
		text-align: center;
		h3 {
			font-size: 30px;
			color: #433a5c;
			text-transform: uppercase;
			@media screen and (max-width: 1023px) {
				font-size: 26px;
			}
		}
	}

	.steps__list {
		.steps__row {
			display: flex;
			justify-content: center;
			align-items: flex-end;
			@media screen and (max-width: 767px) {
				flex-direction: column;
				align-items: flex-start;
			}
			.step {
				width: 33.2%;
				@media screen and (max-width: 767px) {
					width: 100%;
				}
			}
		}
		.steps__row--up {
			@media screen and (max-width: 767px) {
				.step {
					.decore,
					.decore-angle {
						order: -1;
					}
				}
			}
			.step--left {
				&:before {
					content: '';
					position: absolute;
					bottom: 40px;
					right: 0;
					width: 50%;
					height: 4px;
					background-color: #433a5c;
					z-index: 0;
					@media screen and (max-width: 767px) {
						width: 4px;
						height: 100%;
						left: 40px;
						bottom: auto;
						top: 0;
					}
				}
			}

			.step--center {
				&:before {
					content: '';
					position: absolute;
					bottom: 40px;
					left: 0;
					width: 50%;
					height: 4px;
					background-color: #433a5c;
					z-index: 0;
					@media screen and (max-width: 767px) {
						width: 4px;
						height: 100%;
						left: 40px;
						bottom: auto;
						top: 0;
					}
				}

				&:after {
					content: '';
					position: absolute;
					bottom: 40px;
					right: 0;
					width: 50%;
					height: 4px;
					background-color: #433a5c;
					z-index: 0;
					@media screen and (max-width: 767px) {
						display: none;
					}
				}
			}

			.step--right {
				&:before {
					content: '';
					position: absolute;
					bottom: 40px;
					left: 0;
					width: 50%;
					height: 4px;
					background-color: #433a5c;
					z-index: 0;
					@media screen and (max-width: 767px) {
						width: 4px;
						height: 100%;
						left: 40px;
						bottom: auto;
						top: 0;
					}
				}
			}
		}

		.steps__row--down {
			align-items: flex-start;
			.step {
				.decore,
				.decore-angle {
					order: -1;
				}
			}

			.step--last {
				@media screen and (max-width: 767px) {
					order: 2
				}
			}

			.step--center {
				@media screen and (max-width: 767px) {
					order: 1
				}
				&:before {
					content: '';
					position: absolute;
					top: 40px;
					right: 0;
					width: 50%;
					height: 4px;
					background-color: #433a5c;
					z-index: 0;
					@media screen and (max-width: 767px) {
						display: none;
					}
				}
			}

			.step--right {
				@media screen and (max-width: 767px) {
					order: 0
				}
				&:before {
					content: '';
					position: absolute;
					top: 40px;
					left: 0;
					width: 50%;
					height: 4px;
					background-color: #433a5c;
					z-index: 0;
					@media screen and (max-width: 767px) {
						width: 4px;
						height: 100%;
						left: 40px;
						bottom: auto;
						top: 0;
					}
				}
			}
		}

		.steps__row--decore-line {
			width: 100%;
			display: flex;
			justify-content: flex-end;
			padding-right: 16.4%;
			box-sizing: border-box;
			@media screen and (max-width: 767px) {
				display: none;
			}
			&:after {
				content: '';
				height: 100px;
				width: 4px;
				background-color: #433a5c;
			}
			
		}

		.step {
			position: relative;
			text-align: center;
			display: flex;
			flex-direction: column;
			align-items: center;
			@media screen and (max-width: 767px) {
				flex-direction: row;
				margin-bottom: 0;
				padding-bottom: 40px;
				text-align: left;
				align-items: center;
			}
			span {
				position: relative;
				font-size: 18px;
				line-height: 22px;
				color: #433a5c;
				font-style: italic;
				z-index: 10;
				padding: 20px 20px;
				box-sizing: border-box;
				@media screen and (max-width: 1023px) {
					padding: 15px 15px;
					font-size: 16px;
					line-height: 20px;
				}
				@media screen and (max-width: 767px) {
					padding-top: 0;
				}
			}
			.decore {
				position: relative;
				display: flex;
				align-items: center;
				justify-content: center;
				position: relative;
				width: 80px;
				height: 80px;
				background-color: #433a5c;
				border-radius: 50%;
				z-index: 10;
				flex: 0 0 auto;
				@media screen and (max-width: 767px) {
					align-items: center;
				}	
				&:before {
					content: '';
					position: absolute;
					width: 60px;
					height: 60px;
					margin: auto;
					background-color: #f8e71f;
					border-radius: 50%;
				}
				span {
					position: relative;
					font-size: 30px;
					color: #433a5c;
					font-style: normal;
					font-weight: bold;
					padding: 0;
				}
			}

			.decore-angle {
				position: relative;
				width: 94px;
				height: 80px;
				.decore-angle--1 {
					position: absolute;
					top: 0;
					left: 0;
					border: 47px solid transparent;
					border-top: 80px solid #433a5c;
					margin-bottom: 0px;
					@media screen and (max-width: 767px) {
						border: 40px solid transparent;
						border-top: 70px solid #433a5c;
					}
				}
				.decore-angle--2 {
					position: absolute;
					top: 8px;
					left: 14px;
					border: 33px solid transparent;
					border-top: 55px solid #f8e71f;
					margin-bottom: 0px;
					@media screen and (max-width: 767px) {
						left: 15px;
						border: 25px solid transparent;
						border-top: 43px solid #f8e71f;
					}
				}

				.decore-angle--3 {
					position: absolute;
					top: 18px;
					left: 31px;
					border: 16px solid transparent;
					border-top: 27px solid #9f9417;
					margin-bottom: 0px;
					@media screen and (max-width: 767px) {
						top: 17px;
						left: 29px;
						border: 11px solid transparent;
						border-top: 20px solid #9f9417;
						margin-bottom: 0px;
					}
				}
			}
		}

		.step--last {
			span {
				@media screen and (max-width: 767px) {
					padding-left: 45px;
				}
			}
		}

	}
}